<template>
  <fixed-header
    v-model="fixed"
    :threshold="170"
  >
    <div class="header">
      <div class="container">
        <div class="header__inner">
          <div class="header__language">
            <button
              type="button"
              class="btn-lang"
              :class="{ active: language === 'es' }"
              @click="setLanguage('es')"
            >ES</button> / <button
              type="button"
              class="btn-lang"
              :class="{ active: language === 'en' }"
              @click="setLanguage('en')"
            >EN</button>
          </div>
          <div class="header__logo">
            <h1>
              <router-link :to="{ name: 'HomePage' }">
                <img src="../assets/logo.png" alt="Pacific Engineering logo" /><span>Pacific Engineering</span>
              </router-link>
            </h1>
          </div>
          <div class="header__menu">
            <ul class="nav">
              <li>
                <router-link class="a-link" :to="{ name: 'AboutPage', params: { type: 'about' } }">{{lang.menu.about_title}}</router-link>
              </li>
              <li>
                <router-link class="a-link" :to="{ name: 'HomePage', hash: '#service' }">
                  {{lang.menu.service_title}}
                </router-link>
              </li>
              <li class="logo">
                <router-link :to="{ name: 'HomePage' }">
                  <img src="../assets/logo.png" alt="Pacific Engineering logo" />
                </router-link>
              </li>
              <li>
                <router-link class="a-link" exact :to="{ name: 'ClientsPage' }">{{lang.menu.customers_title}}</router-link>
              </li>
              <li>
                <router-link class="a-link" exact :to="{ name: 'NewsPage' }">{{lang.menu.news_title}}</router-link>
              </li>
              <li>
                <router-link class="a-link" exact :to="{ name: 'ContactPage' }">{{lang.menu.contact_title}}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </fixed-header>
</template>
<script>
import FixedHeader from "@/ui/FixedHeader";
export default {
  name: "HeaderSection",
  components: {
    FixedHeader,
  },
  data() {
    return {
      fixed: false,
      isHomePage: false,
    };
  },
  watch: {
    $route(val) {
      this.isHomePage = val.name === "HomePage";
    },
  },
  methods: {
    setLanguage(lang) {
      // this.loadLanguage = true;
      window.localStorage.setItem("pacificLang", lang);
      // this.loadLanguage = false;
      window.location.reload(true);
    },
  }
}
</script>