import axios from 'axios'
axios.defaults.baseURL = 'https://pacific-eng.com/dailypacific/public/api/blogs'

axios.interceptors.request.use(config => {
  // if (store.getters.currentUser.token) {
  //   // ${store.getters.currentUser.token
  //   config.headers.Authorization = `Token ${store.getters.currentUser.token}`
  // }
  return config
}, err => {
  return Promise.reject(err)
})
export default axios
