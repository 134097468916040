import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '*',
      redirect: '/inicio'
    },
    {
      path: '/inicio',
      name: 'HomePage',
      component: () => import('../view/HomePage.vue')
    },
    {
      path: '/admin',
      name: 'LoginPage',
      component: () => import('../view/LoginPage.vue')
    },
    {
      path: '/contact',
      name: 'ContactPage',
      component: () => import('../view/ContactPage.vue')
    },
    {
      path: '/nosotros/:type?',
      name: 'AboutPage',
      props: true,
      component: () => import('../view/AboutPage.vue')
    },
    {
      path: '/clientes',
      name: 'ClientsPage',
      component: () => import('../view/ClientsPage.vue')
    },
    {
      path: '/noticias',
      name: 'NewsPage',
      component: () => import('../view/NewsPage.vue')
    },
    {
      path: '/noticias/:id',
      name: 'NewsDetailPage',
      props: true,
      component: () => import('../view/NewsDetailPage.vue')
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
})
