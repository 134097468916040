<template>
  <div id="app">
    <header-section v-if="!hasMaintenance" />
    <router-view :news="news"></router-view>
    <footer-section v-if="!hasMaintenance" />
  </div>
</template>
<script>
import HeaderSection from '@/layout/HeaderSection';
import FooterSection from '@/layout/FooterSection';

export default {
  name: 'app',
  data: () => ({
    news: []
  }),
  components: {
    HeaderSection,
    FooterSection
  },
  computed: {
    hasMaintenance() {
      const { name } = this.$route;
      return name === "MaintenancePage" || name === "LoginPage";
    },
  },
  mounted() {
    this.fetchNews();
  },
  methods: {
    fetchNews() {
      this.axios.get().then(({ data }) => {
        this.news = data;
        // eslint-disable-next-line no-console
        console.log(data);
      }).catch(() => {
        this.news = [];
      })
    }
  },
  
}
</script>
