import Vue from 'vue'
import App from './App.vue'
// analytics
// import VueAnalytics from 'vue-analytics'
// router
import router from './router/'
// http
import axios from './http'
// meta
import VueMeta from 'vue-meta'
// mixins
import Globals from './mixins'
// ui
import VueScrollReveal from "vue-scroll-reveal";
import VueScrollTo from 'vue-scrollto';
require('vue2-simplert/dist/simplert.css')
// use

Vue.use(VueMeta)
Vue.use(VueScrollTo)
Vue.use(VueScrollReveal, {
  reset: false,
  class: "v-scroll-reveal",
  duration: 700,
  delay: 200,
  cleanup: false,
  distance: "50px",
  mobile: true
})
// scss
import './scss/app.scss'

// Vue.use(VueAnalytics, {
//   id: 'UA-129146572-1',
//   router
// })
// mixin
Vue.mixin(Globals)

Vue.config.productionTip = false
Vue.prototype.axios = axios

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
