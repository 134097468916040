export const lang = {
  es: {
    menu: {
      about_title: 'Nosotros',
      service_title: 'Servicios',
      news_title: 'Blog',
      customers_title: 'Clientes',
      contact_title: 'Contacto',
    },
    footer: {
      text1: 'Para mayor información contáctanos a '
    },
    home: {
      slider: {
        title: `¿Hasta dónde quieres que<br />te ayudemos a crecer?`
      },
      digit: {
        block1: "PROFESIONALES",
        block2: "PAÍSES",
        block3: "DELEGACIONES",
        block4: "CLIENTES"
      },
      news: {
        title: "ÚLTIMAS NOTICIAS",
        btn_go: "LEER MÁS",
        btn_more: "VER MÁS NOTICIAS"
      }
    },
    about: {
      tabs: {
        tab1: "QUIÉNES SOMOS",
        tab2: "SOCIOS PRINCIPALES"
      },
      tab1: {
        text1: "El primer integrador local de soluciones para Minería, Industria y Construcción.Con sede en Lima, cubrimos la región andina: Colombia, Ecuador, Perú, Bolivia y Chile. Proporcionamos productos y servicios dependiendo de la combinación correcta entre nuestros clientes y las empresas a la que buscan servir.",
        text2: "Somos un integrador local de soluciones para la Industria con experiencia especializada en Minería, Infraestructura y Montajes.",
        text3: "Con sede en Lima cubrimos la región Andina: Bolivia, Chile, Colombia, Ecuador y Perú.",
        text4: "Armamos soluciones de productos y servicios haciendo uso de las mejores opciones entre nuestros proveedores y los requerimientos de las empresas que queremos servir.",
        text5: "Proporcionamos productos y Servicios dependiendo de la combinación correcta entre nuestros clientes y los clientes a los que les gustaría servir.",
        text6: "Nuestro trabajo se basa en nuestros conocimientos y valores como lo reflejan las múltiples experiencias que tenemos.",
        text7: "Creemos en:",
        text8: `<ul class="nav nav-custom"><li>Integridad</li><li>Seguridad</li><li>Respeto</li><li>Colaboración</li><li>Excelencia</li><li>Enfoque en el cliente</li></ul>`,
        text9: "DÍGANOS HASTA DONDE QUIEREN LLEGAR"
      },
      tab2: {
        text1: `Tres socios que en su conjunto profesional suman más de 100 años de experiencia. Veintidós asociados, cada uno  de ellos profesionales reconocidos y probados con profundo conocimiento en su campo de experiencia.`,
        text2: `CEO y empresario, experiencia en desarrollo de empresas importantes, con gran conocimiento de los mercados de America y Europa en los sectores minera, manufactura, construcción, agro-industria y contratista.`,
        text3: "PAD y MBA U. de Piura (Lima), Phd Caltech (USA), MS Caltech (USA), Ingeniero Mecánico Eléctrico de la Universidad Libre de Bruselas (Belgica).",
        text4: "Gerente General Grupo Jardines",
        text5: "Gerente General San Martìn Contratistas Generales S.A.",
        text6: "Gerente de Operaciones IEQSA.",
        text7: "Ejecutivo con amplia experiencia en desarrollo de negocios, turn-arround’s, manejo de equipos multi culturales, seguridad y cambio de cultura corporativa.<br/>Ha trabajado en operaciones, ventas, QA/QC, gerencias, minas, geología, metalurgia e industria.",
        text8: "PADE MBA ESAN (Lima), Metalurgista  AGH/ UL (Polonia/Lima).",
        text9: "Gerente General FASTPACK S.A.C.",
        text10: "Gerente Comercial San Martin Contratistas Generales S.A.",
        text11: "Gerente General Inspectorate Services Peru S.A.C.",
        text12: "Gerente Comercial EXSA",
        text13: "Gerente Comercial MEPSA",
        text14: "Empresario, fundador de Pacific Resources, Chile referente con más de 30 años de presencia en a industria de metales en Chile, Brasil, Argentina y Perú. Excelente conocimiento del mercado latinoamericano y Europeo.",
        text15: "MBA Columbia (USA), Ingeniero Civil Universidad de Chile (Chile).",
        text16: "Gerente General Pacific Resources",
        text17: "Director Comercial Methanex",
        text18: "Trader de cobre en CODELCO",
      }
    },
    contact: {
      info1: 'Teléfono',
      info2: 'Correo',
      form: {
        name: 'Nombre y apellido(*)',
        company: "Empresa(*)",
        cargo: "Cargo(*)",
        email: "E-mail(*)",
        phone: "Teléfono(*)",
        requeriment: "Requerimiento(*)",
        send: "Enviar",
        error: "Ingrese los campos requeridos(*)",
        close: "Cerrar",
        message: "Se envio correctamente su mensaje, en breve nos comunicamos con usted."
      }
    }
  },
  en: {
    menu: {
      about_title: 'About Us',
      service_title: 'Services',
      news_title: 'Blog',
      customers_title: 'Customers',
      contact_title: 'Contact Us',
    },
    footer: {
      text1: 'For more information contact us '
    },
    home: {
      slider: {
        title: `HOW FAR DO YOU WANT US TO HELP YOU?`
      },
      digit: {
        block1: "PROFESSIONALS",
        block2: "COUNTRIES",
        block3: "DELEGATIONS",
        block4: "CLIENTS"
      },
      news: {
        title: "LAST NEWS",
        btn_go: "READ MORE",
        btn_more: "VIEW MORE"
      }
    },
    about: {
      tabs: {
        tab1: "ABOUT US",
        tab2: "MAIN PARTNERS"
      },
      tab1: {
        text1: "The first local integrator of solutions for Mining, Industry and Construction. Based in Lima, we cover the Andean region; starting with Peru, we can reach Bolivia, Chile, Colombia and Ecuador. We provide products and services depending on the right match between our customers and the clients they would like to serve.",
        text2: "We believe in:",
        text3: `<ul class="nav nav-custom"><li>Integrity</li><li>Safety</li><li>Respect</li><li>Collaboration</li><li>Excellence</li><li>Customer Focus</li></ul>`,
        text4: "TELL US HOW FAR YOU WANT TO GO"
      },
      tab2: {
        text1: "Three partners whose professional experiences add up to more than 100 years. Twenty two associates, each known and proved professional with deep knowledge of his field of expertise.",
        text2: "CEO and Entrepreneur. Turn-around of major businesses. Extensive experience in America and Europe. Mining, Manufacturing, Construction, Agro-export and Contracting Services",
        text3: "PAD Executive MBA U. Piura (Lima), PhD Caltech (USA), MS Caltech (USA), Electrical and Mechanical Engineer, Brussels Free University (Belgium)",
        text4: "CEO Grupo Jardines",
        text5: "CEO San Martìn",
        text6: "COO IEQSA",
        text7: "Exexcutive with experience, business development, turn-arround`s, multi cultural teams, safety and corporate culture change. Excellent knowledge of LATAM markets. He has been in positions of operations, sales, QA/QC, management, mining, geology, metallurgy and industry.",
        text8: "PADE MBA ESAN (Lima), Metallurgist AGH (Poland) U. Lima (Perù)",
        text9: "CEO Fastpack",
        text10: "Comercial Director San Martìn",
        text11: "CEO ISP/BV",
        text12: "Comercial manager EXSA",
        text13: "MEPSA, SGS",
        text14: "Entrepreneur-owner of Pacific Resources Chile, a reference for 30 years in the metals industry in Chile, Brasil, Argentina and Peru. Excellent knowledge of LATAM, North American and European markets.",
        text15: "MBA Columbia (USA), Civil Engineer U de Chile (Chile).",
        text16: "CEO Pacific Resources",
        text17: "Commercial Director Methanex",
        text18: "Copper trader at CODELCO.",
      }
    },
    contact: {
      info1: 'Phone number',
      info2: 'Email',
      form: {
        name: 'Full name(*)',
        company: "Company(*)",
        cargo: "Position job(*)",
        email: "E-mail(*)",
        phone: "Phone number(*)",
        requeriment: "Requeriments(*)",
        send: "Send",
        error: "Enter the required fields(*)",
        close: "Close",
        message: "Your message was sent correctly, we will contact you shortly."
      }
    }
  }
}