<template>
  <div class="footer">
    <div class="footer__top">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-4">
            <div class="b-pacific">
              <img src="@/assets/logo.png" alt="Pacific logo">
              <p>{{lang.footer.text1}}<a href="mailto:contact@pacificeng.pe">contact@pacificeng.pe</a></p>
            </div>
          </div>
          <div class="col-xs-12 col-md-3 col-md-offset-4">
            <h5>Pacific</h5>
            <ul class="nav">
              <li>
                <router-link class="a-link" :to="{ name: 'AboutPage', params: { type: 'about' } }">{{lang.menu.about_title}}</router-link>
              </li>
              <li>
                <router-link class="a-link" :to="{ name: 'HomePage', hash: '#service' }">
                  {{lang.menu.service_title}}
                </router-link>
              </li>
              <li>
                <router-link class="a-link" exact :to="{ name: 'NewsPage' }">{{lang.menu.news_title}}</router-link>
              </li>
              <li>
                <router-link class="a-link" exact :to="{ name: 'ContactPage' }">{{lang.menu.contact_title}}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container">
        <div class="divider-line"></div>
        <p class="copy">Copyright 2020</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "FooterSection",
  computed: {
    ...mapState({
      menu: state => state.language.menu
    })
  }
};
</script>
